<template>
  <footer
    class="site-footer w-100 bg-white p-8 sm:py-12 sm:px-8 xxl:p-14"
    :class="{ ready: elementsInView }"
    ref="footer"
  >
    <div class="flex justify-center">
      <div
        class="w-full font-light flex flex-col sm:flex-row justify-between uppercase text-center text-lg sm:text-xl leading-tight md:text-left xxl:text-2xl xl:w-10/12 xxl:w-1/2 "
      >
        <div class="mb-4 md:mb-0">
          <p class="font-serif text-pink mb-1">CONTACT</p>
          <a href="tel:+4402073497099">+44 020 7349 7099</a><br />
          <a href="mailto:niloufar@nbbtrading.com">niloufar@nbbtrading.com</a>
        </div>
        <div>
          <p class="font-serif text-pink mb-1">Address</p>
          <address class="not-italic">
            132-134 World's end studios<br />
            Lots Road, London, SW10 0RJ
          </address>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {
      intersected: false,
      elementsInView: false
    };
  },

  mounted() {
    const _that = this;

    this.observer = new IntersectionObserver(
      function(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            _that.intersected = true;
          }
        });
      },
      { threshold: 0.4 }
    );

    this.observer.observe(this.$refs.footer);
  },

  watch: {
    intersected() {
      this.elementsInView = true;
    }
  }
};
</script>

<style scoped>
.site-footer > div {
  @apply opacity-0;

  transition: opacity 0.8s cubic-bezier(0.13, 0.74, 0.5, 0.97);
}

.site-footer.ready > div {
  @apply opacity-100;
}
</style>
