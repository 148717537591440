<template>
  <div>
    <section>
      <Header />
      <div
        class="flex flex-col justify-center py-6 px-6 mt-12 md:px-16 lg:py-14"
      >
        <div
          class="leading-tight text-center font-light w-full mx-auto xl:w-11/12 xxl:w-2/3"
        >
          <div class="text-xl lg:text-5xl uppercase font-serif">
            Page not found
          </div>

          <div
            class="text-xl mb-8 leading-tight lg:text-2xl lg:mb-18 uppercase"
          >
            Sorry we couldnt find the page you were looking for.
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../components/Header";
export default {
  name: "Home",
  components: {
    Header
  }
};
</script>
