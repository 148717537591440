<template>
  <header
    class="site-header w-full px-4 py-6 xl:py-8 xl-px-8 xxl:py-14 xxl:px-14 absolute top-0 z-10"
  >
    <router-link to="/">
      <Logo class="w-16 xl:w-24 xxl:w-32" />
    </router-link>
  </header>
</template>
<script>
import Logo from "../assets/images/NBBLogoMark.svg";

export default {
  name: "Header",
  components: {
    Logo
  }
};
</script>
