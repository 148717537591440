<template>
  <div
    id="app"
    class="bg-lavender antialiased font-sans leading-normal tracking-normal flex flex-col min-h-screen w-screen"
  >
    <router-view />
  </div>
</template>

<script>
export default {};
</script>
